import { isNotNullOrEmptyString, isNullOrEmptyString } from '@app/utils';

import { ListItemResponse, useEsaModel } from './useEsaModel';

import { useEnqueueSnackbar } from '@/Hooks/Snackbar';
import { Partner } from '@/Models/Esa/Partner';
import { SwrModelState } from '@/Models/Swr';
import { ServiceConfigure } from '@/Services/Configure';
import { useEsaApiClient } from '@/Services/EsaApiService';

export type PartnerListItemResponse = ListItemResponse<Partner>;

export type PartnerState = SwrModelState<PartnerListItemResponse | null>;

export type PartnerQuery = {
  startIndex: number;
  itemsPerPage: number;
  organizationUids?: string;
  partnerOrganizationUids?: string;
  partnerOrganizationGuids?: string;
  expandDomain?: boolean;
  expandOrganization?: boolean;
};

const DEFAULT_START_INDEX = 1;
const DEFAULT_PER_PAGE = 10;
const getPartnerQueryParams = (partnerQuery: PartnerQuery): URLSearchParams => {
  const params = new URLSearchParams();
  const startIndex = partnerQuery.startIndex ?? DEFAULT_START_INDEX;
  params.append('startIndex', startIndex.toString());
  const itemsPerPage = partnerQuery.itemsPerPage ?? DEFAULT_PER_PAGE;
  params.append('itemsPerPage', itemsPerPage.toString());
  if (partnerQuery?.organizationUids) {
    params.append('organizationUids', partnerQuery.organizationUids);
  }
  if (partnerQuery?.partnerOrganizationUids) {
    params.append('partnerOrganizationUids', partnerQuery.partnerOrganizationUids);
  }
  if (partnerQuery?.partnerOrganizationGuids) {
    params.append('partnerOrganizationGuids', partnerQuery.partnerOrganizationGuids);
  }
  if (partnerQuery?.expandDomain) {
    params.append('expandDomain', partnerQuery.expandDomain ? 'true' : 'false');
  }
  if (partnerQuery?.expandOrganization) {
    params.append('expandOrganization', partnerQuery.expandOrganization ? 'true' : 'false');
  }
  return params;
};

export const usePartners = (partnerQuery: PartnerQuery): PartnerState => {
  const partnerParams = getPartnerQueryParams(partnerQuery);
  const queryString = partnerParams.toString();

  const { isLoading, error, model, mutate, isValidating } = useEsaModel<PartnerListItemResponse | null>({
    api: {
      audience: ServiceConfigure.auth0Audience,
      baseUrl: ServiceConfigure.esAccountApi,
      path: isNotNullOrEmptyString(queryString) ? `/partners?${queryString}` : null,
    },
  });

  return {
    isLoading,
    error,
    model,
    mutate,
    isValidating,
  };
};

export const useDeletePartner = (): {
  deletePartner: (partnerUid: string) => Promise<void>;
} => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { apiClient } = useEsaApiClient({
    audience: ServiceConfigure.auth0Audience,
    baseUrl: ServiceConfigure.esAccountApi,
  });

  const deletePartner = async (partnerUid: string) => {
    try {
      if (isNullOrEmptyString(partnerUid)) {
        throw new Error('取引先が見つかりません');
      }
      await apiClient?.delete<Partner>({ path: `/partners/${partnerUid}` });
      enqueueSnackbar('取引先から削除しました', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('取引先から削除できませんでした。しばらく経ってから再度お試しください。', { variant: 'error' });
    }
  };

  return { deletePartner };
};
