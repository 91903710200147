import { Auth0Provider as Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { ServiceConfigure } from '@/Services/Configure';

const returnUrlWhenLogin = 'ReturnUrlWhenLogin';

const Auth0Provider = ({ children }: { children: ReactNode }): JSX.Element => {
  const navigate = useNavigate();
  const onRedirectCallback = (): void => {
    navigate(sessionStorage.getItem(returnUrlWhenLogin) ?? '');
  };

  return (
    <Provider
      domain={ServiceConfigure.auth0Domain ?? ''}
      clientId={ServiceConfigure.auth0ClientId ?? ''}
      authorizationParams={{
        redirect_uri: ServiceConfigure.auth0RedirectUrl,
        audience: ServiceConfigure.auth0Audience,
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Provider>
  );
};
export default Auth0Provider;
