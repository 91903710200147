import { css } from '@emotion/react';
import { Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { OpenInNew } from 'mdi-material-ui';
import { FC, memo } from 'react';

import { supportSiteUrl } from '@/Consts/ExternalSiteUrls';
import { ServiceConfigure } from '@/Services/Configure';
import { theme } from '@/Theme';

type NotificationSummary = {
  content: string;
  target: string;
  means: string;
};

const NotificationSettingContainer: FC = memo(() => {
  const notificationHeader = ['通知内容', '通知対象', '通知手段'];
  const notificationSummary: NotificationSummary[] = [
    { content: '取引先申請の受信', target: 'アカウント管理者', means: 'メール' },
    { content: '取引先申請の承認', target: 'アカウント管理者、組織全員', means: 'メール' },
    { content: '取引先申請の却下', target: 'アカウント管理者、組織全員', means: 'メール' },
    { content: '取引先申請の取消', target: 'アカウント管理者', means: 'メール' },
    {
      content: 'メッセージの受信',
      target: 'アカウント管理者、組織全員',
      means: 'メール、ブラウザ通知※',
    },
  ];
  return (
    <NotificationSettingPresenter notificationHeader={notificationHeader} notificationSummary={notificationSummary} />
  );
});

export { NotificationSettingContainer as NotificationSetting };

type NotificationSettingPresenterProps = {
  notificationHeader: string[];
  notificationSummary: NotificationSummary[];
};

const tableStyle = css({
  paddingTop: '8px',
  paddingBottom: '8px',
});

export const NotificationSettingPresenter: FC<NotificationSettingPresenterProps> = memo(
  ({ notificationHeader, notificationSummary }) => (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="var(--color-semantic-bg-white)"
      border="solid 1px var(--color-semantic-border-divider)"
      borderRadius="var(--radius-content-box)"
      p={3}
      mb={3}
      gap={1}
    >
      <Box fontWeight="bold" fontSize="20px">
        通知
      </Box>
      <Box color="var(--color-semantic-text-secondary)">取引先申請やメッセージの通知状況は以下の通りです。</Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow css={{ backgroundColor: theme.palette.secondary.light }}>
              {notificationHeader.map(item => {
                return (
                  <TableCell key={item} css={tableStyle}>
                    {item}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {notificationSummary.map(item => {
            return (
              <TableBody key={item.content}>
                <TableRow>
                  {Object.entries(item).map(([key, value]) => {
                    return (
                      <TableCell key={key} css={tableStyle}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
      <Box color="var(--color-semantic-text-secondary)">
        ※メッセージ受信時のメール通知設定は『いい生活アカウント』の
        <Link
          href={`${ServiceConfigure.esAccountUrl}/profile/edit`}
          target="_blank"
          rel="noopener noreferrer"
          display="inline"
        >
          個人設定
          <OpenInNew css={{ verticalAlign: 'middle', fontSize: '16px' }} />
        </Link>
        から変更できます。
      </Box>
      <Box color="var(--color-semantic-text-secondary)">
        ブラウザ通知の設定方法は
        <Link
          href={`${supportSiteUrl}/knowledge-base/メッセージのブラウザ通知設定を行う`}
          target="_blank"
          rel="noopener noreferrer"
          display="inline"
        >
          サポートサイト
          <OpenInNew css={{ verticalAlign: 'middle', fontSize: '16px' }} />
        </Link>
        をご確認ください。
      </Box>
    </Box>
  )
);
