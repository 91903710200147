import useSWR from 'swr';

import { bannerAdsUrl } from '@/Consts/ExternalSiteUrls';
import { reportError } from '@/ErrorLogger';
import { SwrModelState } from '@/Models/Swr';

export type BannerItem = {
  /* eslint-disable @typescript-eslint/naming-convention */
  Url: string;
  Image: string;
  /* eslint-able @typescript-eslint/naming-convention */
};

type BannerItems = {
  BannerItems: BannerItem[];
};

export const useBannerHook = (): SwrModelState<BannerItems> => {
  const fetcher = (url: string) =>
    fetch(url, {
      method: 'GET',
      cache: 'no-cache',
    })
      .then(res => res.json())
      .catch(error => reportError(`バナー広告の取得に失敗しました。${error}`, 'useBannerHook'));

  const { data, error, isLoading, mutate, isValidating } = useSWR<BannerItems>(bannerAdsUrl, fetcher, {
    revalidateOnFocus: false,
  });

  return { model: data ?? null, error, isLoading, mutate, isValidating };
};
