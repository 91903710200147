import { css } from '@emotion/react';
import { Loading, useIsExtraSmallDevice, useOnOffState } from '@lib/components';
import { Box, Button, Link } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ChevronLeft, ChevronRight } from 'mdi-material-ui';
import { useState, Fragment, useMemo, FC, useEffect, useCallback } from 'react';
import { SwipeableHandlers, useSwipeable } from 'react-swipeable';

import { ebSfaServiceSiteUrl } from '@/Consts/ExternalSiteUrls';
import { BannerItem, useBannerHook } from '@/Hooks/BannerHooks';

const BannerListContainer: FC = () => {
  const isExtraSmallDevice = useIsExtraSmallDevice();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, { setTrue: setIsHoveredTrue, setFalse: setIsHoveredFalse }] = useOnOffState(false);

  const { model, isLoading } = useBannerHook();
  const bannerList = useMemo(() => {
    if (!model) {
      return [];
    }
    return model.BannerItems;
  }, [model]);
  const currentBanner = bannerList[currentIndex];

  const handleNextBanner = useCallback(() => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % bannerList.length);
  }, [bannerList.length]);

  const handlePrevBanner = useCallback(() => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? bannerList.length - 1 : prevIndex - 1));
  }, [bannerList.length]);

  const handlers = useSwipeable({
    onSwipedLeft: handleNextBanner,
    onSwipedRight: handlePrevBanner,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNextBanner();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [handleNextBanner]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BannerListPresenter
      isExtraSmallDevice={isExtraSmallDevice}
      isHovered={isHovered}
      setIsHoveredTrue={setIsHoveredTrue}
      setIsHoveredFalse={setIsHoveredFalse}
      currentBanner={currentBanner}
      handleNextBanner={handleNextBanner}
      handlePrevBanner={handlePrevBanner}
      handlers={handlers}
    />
  );
};

export { BannerListContainer as BannerList };

type BannerListPresenterProps = {
  isExtraSmallDevice: boolean;
  isHovered: boolean;
  setIsHoveredTrue: () => void;
  setIsHoveredFalse: () => void;
  currentBanner: BannerItem;
  handleNextBanner: () => void;
  handlePrevBanner: () => void;
  handlers: SwipeableHandlers;
};

const switchButtonStyle = css({
  width: '3rem',
  height: '30%',
  backgroundColor: `${grey[600]}`,
  opacity: 0.7,
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  '&:hover': {
    backgroundColor: `${grey[900]}`,
  },
});

const chevronLeftButtonStyle = css(switchButtonStyle, {
  left: 0,
});

const chevronRightButtonStyle = css(switchButtonStyle, {
  right: 0,
});

export const BannerListPresenter: FC<BannerListPresenterProps> = ({
  isExtraSmallDevice,
  isHovered,
  setIsHoveredTrue,
  setIsHoveredFalse,
  currentBanner,
  handleNextBanner,
  handlePrevBanner,
  handlers,
}) => {
  return (
    <Box position="relative" width="100%" {...handlers}>
      <Box
        position="relative"
        css={{
          cursor: 'pointer',
        }}
        onMouseEnter={setIsHoveredTrue}
        onMouseLeave={setIsHoveredFalse}
      >
        <Link
          href={
            currentBanner?.Url
              ? currentBanner.Url
              : `${ebSfaServiceSiteUrl}?utm_source=Square_banner&utm_medium=Square&utm_campaign=Square_banner`
          }
          target="_blank"
          rel="noopener noreferrer"
          display="flex"
        >
          {currentBanner?.Image ? (
            <img
              src={currentBanner.Image}
              alt="いい生活Square_サイド広告"
              style={{ objectFit: 'contain', width: '100%' }}
            />
          ) : (
            <img src="/sfa_side_banner.png" alt="いい生活賃貸クラウド営業支援_サイド広告" width="100%" />
          )}
        </Link>
      </Box>

      {!isExtraSmallDevice && isHovered && (
        <Fragment>
          <Button
            onClick={handlePrevBanner}
            css={chevronLeftButtonStyle}
            className="prev"
            onMouseEnter={setIsHoveredTrue}
            onMouseLeave={setIsHoveredFalse}
          >
            <ChevronLeft color="secondary" fontSize="large" />
          </Button>
          <Button
            css={chevronRightButtonStyle}
            onClick={handleNextBanner}
            className="next"
            onMouseEnter={setIsHoveredTrue}
            onMouseLeave={setIsHoveredFalse}
          >
            <ChevronRight color="secondary" fontSize="large" />
          </Button>
        </Fragment>
      )}
    </Box>
  );
};
