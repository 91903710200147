import { isNotNullOrEmptyString } from '@app/utils';

import { ListItemResponse, useEsaModel } from './useEsaModel';

import { EsaPublicDomain } from '@/Models/Esa/EsaDomain';
import { EsaPublicOrganization } from '@/Models/Esa/EsaOrganization';
import { SwrModelState } from '@/Models/Swr';
import { ServiceConfigure } from '@/Services/Configure';

export type EsaPublicDomainListItemResponse = ListItemResponse<EsaPublicDomain>;
export type EsaPublicOrganizationListItemResponse = ListItemResponse<EsaPublicOrganization>;

export type EsaPublicDomainState = SwrModelState<EsaPublicDomainListItemResponse | null>;
export type EsaPublicOrganizationState = SwrModelState<EsaPublicOrganizationListItemResponse | null>;

export const PUBLIC_DEFAULT_START_INDEX = 1;
export const PUBLIC_DEFAULT_PER_PAGE = 100;

export const useEsaPublicDomain = (query?: URLSearchParams): EsaPublicDomainState => {
  const searchParams = new URLSearchParams(query);
  searchParams.append('startIndex', `${PUBLIC_DEFAULT_START_INDEX}`);
  searchParams.append('itemsPerPage', `${PUBLIC_DEFAULT_PER_PAGE}`);
  const queryString = searchParams.toString();

  const { isLoading, error, model, mutate, isValidating } = useEsaModel<EsaPublicDomainListItemResponse | null>({
    api: {
      audience: ServiceConfigure.auth0Audience,
      baseUrl: ServiceConfigure.esAccountApi,
      path: isNotNullOrEmptyString(queryString) ? `/public/domains?${queryString}` : null,
    },
  });

  return {
    isLoading,
    error,
    model,
    mutate,
    isValidating,
  };
};

export const useEsaPublicOrganization = (query?: URLSearchParams): EsaPublicOrganizationState => {
  const searchParams = new URLSearchParams(query);
  searchParams.append('itemsPerPage', `${PUBLIC_DEFAULT_PER_PAGE}`);
  const queryString = searchParams.toString();

  const { isLoading, error, model, mutate, isValidating } = useEsaModel<EsaPublicOrganizationListItemResponse | null>({
    api: {
      audience: ServiceConfigure.auth0Audience,
      baseUrl: ServiceConfigure.esAccountApi,
      path: isNotNullOrEmptyString(queryString) ? `/public/organizations?${queryString}` : null,
    },
  });

  return {
    isLoading,
    error,
    model,
    mutate,
    isValidating,
  };
};
