/* eslint-disable @typescript-eslint/naming-convention */
import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    background: {
      default: grey[100],
    },
    primary: {
      main: '#035DAD',
    },
    secondary: {
      main: '#DEEBF4',
      light: '#F2F7FB',
    },
    attention: {
      main: '#E7421E',
    },
    accent: {
      main: '#52A0D6',
    },
  },
  typography: {
    fontFamily: ['"Hiragino Kaku Gothic ProN"', 'Meiryo', 'sans-serif'].join(','),
    body1: {
      fontSize: '0.875rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          overflowWrap: 'break-word',
        },
        subtitle1: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          lineHeight: '1.3',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
});
