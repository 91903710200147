import { AuthenticationNotGetTokenError } from '@app/errors';
import useSWR from 'swr';

import { SwrModelState } from '@/Models/Swr';
import { useEsaApiClient } from '@/Services/EsaApiService';

type Prop = {
  api: {
    audience: string;
    baseUrl: string;
    path: string | null;
  };
  refreshInterval?: number;
};

export type ListItemResponse<T> = {
  startIndex: number;
  itemsPerPage: number;
  totalCounts: number;
  items: T[];
};

export const useEsaModel = <T>({ api: { audience, baseUrl, path }, refreshInterval }: Prop): SwrModelState<T> => {
  const { isLoading, apiClient, error: authError } = useEsaApiClient({ audience, baseUrl });

  // apiClientの準備中の場合はリクエストを抑制
  // authError が解消した場合にも再取得できるようにする
  const suspend = isLoading || !!authError;

  const { data, error, mutate, isValidating } = useSWR(
    suspend || path === null ? null : path,
    pathname => {
      if (authError) {
        // 認証で既にエラーを返していたら引き継ぐ
        throw authError;
      }
      if (!apiClient) {
        // isLoading = false なので到達しないはず。SWR が型推論を破壊している
        throw new Error('Unreachable code');
      }
      return apiClient.get<T>({ path: pathname });
    },
    {
      revalidateOnFocus: false,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // ログイン画面での無駄なリクエスト発生を防ぐため
        if (error instanceof AuthenticationNotGetTokenError) {
          return;
        }
        // 5秒後に再試行します。
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
      refreshInterval: refreshInterval || 0,
    }
  );
  if (error) {
    return {
      isLoading: false,
      error,
      model: null,
      mutate,
      isValidating,
    };
  }
  if (!data) {
    return {
      isLoading: path !== null, // pathがnullの時はそもそもリクエストを送信していないので、loadingとは判定しない
      error: null,
      model: null,
      mutate,
      isValidating,
    };
  }

  return {
    isLoading: false,
    error: null,
    model: data,
    mutate,
    isValidating,
  };
};
