import { isNotNullOrUndefined } from '@app/utils';
import { Alert } from '@e-seikatsu/design-system';
import { Box } from '@mui/material';
import { format, isAfter } from 'date-fns';
import { FC } from 'react';

import { MaintenanceInfo } from '@/Components/DefaultLayout';
import { isInvalidDate } from '@/Pages/bukken/chintai/SearchPartial/BukkenSearchForms/SearchConditionValidHooks';

type MaintenanceAlertContainerProps = {
  maintenanceInfo: MaintenanceInfo | undefined;
};
const MaintenanceAlertContainer: FC<MaintenanceAlertContainerProps> = ({ maintenanceInfo }) => {
  // oneAPIのメンテナンス時間帯
  const eboneMaintenanceFrom = new Date(String(maintenanceInfo?.oneApiSchedule?.from));
  const eboneMaintenanceTo = new Date(String(maintenanceInfo?.oneApiSchedule?.to));
  const isEboneMaintenanceSchedule =
    isNotNullOrUndefined(eboneMaintenanceFrom) &&
    isNotNullOrUndefined(eboneMaintenanceTo) &&
    !isInvalidDate(eboneMaintenanceFrom) &&
    !isInvalidDate(eboneMaintenanceTo);

  // square物件検索のメンテナンス時間帯
  const rentMaintenanceFrom = new Date(String(maintenanceInfo?.rentSchedule?.from));
  const rentMaintenanceTo = new Date(String(maintenanceInfo?.rentSchedule?.to));
  const isRentMaintenanceSchedule =
    isNotNullOrUndefined(rentMaintenanceFrom) &&
    isNotNullOrUndefined(rentMaintenanceTo) &&
    !isInvalidDate(rentMaintenanceFrom) &&
    !isInvalidDate(rentMaintenanceTo);

  const now = new Date();
  const eboneIsAlert = isNotNullOrUndefined(eboneMaintenanceFrom) ? isAfter(eboneMaintenanceFrom, now) : false;
  const eboneFromToText = isEboneMaintenanceSchedule
    ? `${format(eboneMaintenanceFrom, 'M/d H:mm')} 〜 ${format(eboneMaintenanceTo, 'M/d H:mm')}`
    : null;
  const rentIsAlert = isNotNullOrUndefined(rentMaintenanceFrom) ? isAfter(rentMaintenanceFrom, now) : false;
  const rentFromToText = isRentMaintenanceSchedule
    ? `${format(rentMaintenanceFrom, 'M/d H:mm')} 〜 ${format(rentMaintenanceTo, 'M/d H:mm')}`
    : null;
  const fromToText = rentIsAlert ? rentFromToText : eboneIsAlert ? eboneFromToText : null;

  return <MaintenanceAlertPresenter fromToText={fromToText} />;
};

export { MaintenanceAlertContainer as MaintenanceAlert };

type MaintenanceAlertPresenterProps = {
  fromToText: string | null;
};
export const MaintenanceAlertPresenter: FC<MaintenanceAlertPresenterProps> = ({ fromToText }) => {
  return isNotNullOrUndefined(fromToText) ? (
    <Box p={2}>
      <Alert color="warning">
        <Alert.Title>メンテナンスのため{fromToText}の間はサービスをご利用頂けません</Alert.Title>
      </Alert>
    </Box>
  ) : null;
};
