/* API を叩くときのリクエストのコードとラベルの対応関係とマッピング処理を定義する */
/* React 側で管理するラジオボタンの選択状態の定義もここで行う */
import { makeCodeAssertionFunc } from '@app/utils';

export const BukkenDateRangeRadioState = {
  unselected: 'unselected',
  today: 'today',
  threeDays: 'threeDays',
  sevenDays: 'sevenDays',
  select: 'select',
} as const;
export const BukkenDateRangeRadioLabelMap: { [_ in BukkenDateRangeRadioState]: string } = {
  unselected: '指定しない',
  today: '本日',
  threeDays: '3日以内',
  sevenDays: '7日以内',
  select: '期間指定',
};
export type BukkenDateRangeRadioState = typeof BukkenDateRangeRadioState[keyof typeof BukkenDateRangeRadioState];
export const asBukkenDateRangeRadioState = makeCodeAssertionFunc(Object.values(BukkenDateRangeRadioState));

export const ItemsPerPageCollection = {
  '10件': 10,
  '20件': 20,
  '30件': 30,
  '50件': 50,
  '100件': 100,
} as const;
export type ItemPerPageKey = typeof ItemsPerPageCollection[keyof typeof ItemsPerPageCollection];
export const isItemPerPageKey = (key: number): key is ItemPerPageKey => {
  return Object.values(ItemsPerPageCollection).includes(key as ItemPerPageKey);
};

export const ChintaiResultOrder = {
  chinryoAscending: 'chinryo.asc',
  chinryoDescending: 'chinryo.desc',
  lastUpdatedTimeDescending: 'saishu_koshin_time.desc',
  kokokuryoDescending: 'kokokuryo_en.desc',
  mensekiDescending: 'search_menseki.desc',
  chikunengetsuDescending: 'shunko_datejun.desc',
  publishTimeDescending: 'one_network_keisai_kaishi_time.desc',
} as const;
export type ChintaiResultOrder = typeof ChintaiResultOrder[keyof typeof ChintaiResultOrder];
const ChintaiResultOrderToTextMap: Record<ChintaiResultOrder, string> = {
  'chinryo.asc': '賃料の安い順',
  'chinryo.desc': '賃料の高い順',
  'saishu_koshin_time.desc': '最終更新日の新しい順',
  'kokokuryo_en.desc': 'AD の高い順',
  'search_menseki.desc': '面積の広い順',
  'shunko_datejun.desc': '築年月の新しい順',
  'one_network_keisai_kaishi_time.desc': '新着順',
} as const;
export const chintaiResultOrderToText = (code: ChintaiResultOrder | null | undefined): string =>
  code ? ChintaiResultOrderToTextMap[code] : '';
export const asChintaiResultOrder = makeCodeAssertionFunc(Object.values(ChintaiResultOrder));

export const SearchBoshuShubetsuConditions = {
  mansion: '101',
  apartment: '102',
  kodate: '103',
  jigyo: '104',
  chushajo: '106',
  tochi: '108',
} as const;
export type SearchBoshuShubetsuConditions =
  typeof SearchBoshuShubetsuConditions[keyof typeof SearchBoshuShubetsuConditions];
export const SearchBoshuShubetsuConditionsLabelMap: { [_ in SearchBoshuShubetsuConditions]: string } = {
  101: 'マンション',
  102: 'アパート',
  103: '戸建',
  104: '事業用（店舗・事務所）',
  106: '駐車場・バイク置場',
  108: '土地',
};

export const MadoriConditions = {
  codeOneRoom: '1',
  code1K: '2',
  code1DK: '3',
  code1LDK: '4',
  code2K: '5',
  code2DK: '6',
  code2LDK: '7',
  code3K: '8',
  code3DK: '9',
  code3LDK: '10',
  code4K: '11',
  code4DK: '12',
  code4LDK: '13',
  moreThan5K: '14',
} as const;
export type MadoriConditions = typeof MadoriConditions[keyof typeof MadoriConditions];
export const MadoriConditionsLabelMap: { [_ in MadoriConditions]: string } = {
  1: 'ワンルーム',
  2: '1K',
  3: '1DK',
  4: '1LDK',
  5: '2K',
  6: '2DK',
  7: '2LDK',
  8: '3K',
  9: '3DK',
  10: '3LDK',
  11: '4K',
  12: '4DK',
  13: '4LDK',
  14: '5K以上',
};

export const BukkenNyukyoKanoDateRangeRadioState = {
  unselected: 'unselected',
  immediateOccupancy: 'immediateOccupancy',
  discussed: 'discussed',
  select: 'select',
  plan: 'plan',
} as const;
export const BukkenNyukyoKanoDateRangeRadioLabelMap: { [_ in BukkenNyukyoKanoDateRangeRadioState]: string } = {
  unselected: '指定しない',
  immediateOccupancy: '即入居',
  discussed: '相談',
  select: '期間指定',
  plan: '予定含む',
};
export type BukkenNyukyoKanoDateRangeRadioState =
  typeof BukkenNyukyoKanoDateRangeRadioState[keyof typeof BukkenNyukyoKanoDateRangeRadioState];
export const asBukkenNyukyoKanoDateRangeRadioState = makeCodeAssertionFunc(
  Object.values(BukkenNyukyoKanoDateRangeRadioState)
);
export const BukkenNyukyoKanoCodeMap = {
  unselected: '0',
  immediateOccupancy: '1',
  discussed: '2',
  select: '3',
  plan: '4',
} as const;

export const GenkyoConditions = {
  inResidence: '1',
  empty: '2',
  inConstruction: '3',
  expulsionScheduled: '4',
  inRemodeling: '5',
  complete: '6',
} as const;
export type GenkyoConditions = typeof GenkyoConditions[keyof typeof GenkyoConditions];
export const GenkyoConditionsLabelMap: { [_ in GenkyoConditions]: string } = {
  1: '居住中',
  2: '空室',
  3: '建築中',
  4: '退去予定',
  5: '改装中',
  6: '完成済',
};
export const KozoConditions = {
  tekkin: 'tekkin',
  tekkotsu: 'tekkotsu',
  mokuzo: 'mokuzo',
  sonota: 'sonota',
} as const;
export type KozoConditions = typeof KozoConditions[keyof typeof KozoConditions];
export const KozoConditionsLabelMap: { [_ in KozoConditions]: string } = {
  tekkin: '鉄筋系',
  tekkotsu: '鉄骨系',
  mokuzo: '木造',
  sonota: 'その他',
};
export const KozoConditionsParamMap: { [_ in KozoConditions]: string[] } = {
  tekkin: ['鉄筋コンクリート', '鉄筋ブロック', '鉄骨鉄筋コンクリート', 'プレキャストコンクリート'],
  tekkotsu: ['軽量鉄骨', '鉄骨', '鉄骨プレ', 'ALC'],
  mokuzo: ['木造'],
  sonota: ['その他'],
};

export const NaikenConditions = {
  unspecified: '0',
  enable: '1',
  unable: '2',
  planed: '3',
};
export type NaikenConditions = typeof NaikenConditions[keyof typeof NaikenConditions];
export const NaikenConditionsLabelMap: { [_ in NaikenConditions]: string } = {
  0: '　　　',
  1: '内見可',
  2: '内見不可',
  3: '内見予定',
};

export const B2bKokaiCodeRadioState = {
  unselected: '0',
  public: '3',
  partner: '4',
} as const;
export type B2bKokaiCodeRadioStateType = typeof B2bKokaiCodeRadioState[keyof typeof B2bKokaiCodeRadioState];
export const asB2bKokaiCodeRadioState = makeCodeAssertionFunc(Object.values(B2bKokaiCodeRadioState));
export const B2bKokaiCodeLabelMap = {
  0: '指定しない',
  3: '全体公開',
  4: '限定公開',
} as const;
